<template>
  <div :class="$vuetify.breakpoint.name === 'lg' ? 'pr-7 pl-7' : 'px-8'">

    <Snackbar ref="snackbar"/>

    <!-- DIALOG DETAIL -->
    <v-dialog v-model="dialog.detail" persistent width="550">
      <v-card class="border-radius box-shadow">
        <v-card-title
          class="body-2 text-first d-flex mb-4"
          style="background-color: #F05326;"
        >
          <span class="white--text">
            Detail Member
          </span>
          <v-spacer></v-spacer>
          <v-icon @click="dialog.detail = false" color="white">mdi-close-circle</v-icon>
        </v-card-title>
        <v-card-text>
          <!-- <pre>
            {{ member }}
          </pre> -->

          <v-simple-table class="px-3">
            <template>
              <tbody>
                <tr>
                  <th class="text-second pl-0" width="30%">Nama Lengkap</th>
                  <td width="5%" class="text-center text-second">:</td>
                  <td class="text-right text-second">
                    {{ member.fullname }}
                  </td>
                </tr>
                <tr>
                  <th class="text-second pl-0" width="30%">Email</th>
                  <td width="5%" class="text-center text-second">:</td>
                  <td class="text-right text-second">
                    {{ member.email }}
                  </td>
                </tr>
                <tr>
                  <th class="text-second pl-0" width="30%">Provider</th>
                  <td width="5%" class="text-center text-second">:</td>
                  <td class="text-right text-second">
                    {{ member.provider }}
                  </td>
                </tr>
                <tr>
                  <th class="text-second pl-0" width="30%">No Telepon</th>
                  <td width="5%" class="text-center text-second">:</td>
                  <td class="text-right text-second">
                    {{ member.phone }}
                  </td>
                </tr>
                <tr>
                  <th class="text-second pl-0" width="30%">Status Member</th>
                  <td width="5%" class="text-center text-second">:</td>
                  <td class="text-right text-second">
                    <v-chip v-if="member.status == 0" color="red" text-color="white" small>
                      Tidak aktif
                    </v-chip>
                    <v-chip v-if="member.status == 1" color="green" text-color="white" small>
                      Aktif
                    </v-chip>
                  </td>
                </tr>
                <tr>
                  <th class="text-second pl-0" width="30%">Terakhir Login</th>
                  <td width="5%" class="text-center text-second">:</td>
                  <td class="text-right text-second">
                    {{ member.last_login | datetime }}
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- DIALOG CHANGE MEMBERS -->
    <v-dialog v-model="dialog.change_member" persistent width="550">
      <v-card class="border-radius box-shadow">
        <v-card-title
          class="body-2 text-first d-flex mb-4"
          style="background-color: #F05326;"
        >
          <span class="white--text">
            Change Member Redeem
          </span>
          <v-spacer></v-spacer>
          <v-icon @click="dialog.change_member = false;form.member_id = '';" color="white">mdi-close-circle</v-icon>
        </v-card-title>
        <v-card-text>
          <v-form>
            <v-autocomplete
              outlined
              dense
              flat
              v-model="form.member_id"
              :items="items.list"
              label="Pilih Member"
              item-value="id"
              item-text="fullname"
              color="#F05326">
            </v-autocomplete>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn 
            color="#F05326"
            class="white--text text-capitalize"
            depressed
            @click="changeMemberRedeem">
            Simpan Perubahan
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <div class="mt-5">
      <div class="title black--text mb-2">List Member</div>

      <Breadcrumbs :items="breadcrumbs"/>

      <v-row justify="space-between" class="mt-2">
        <v-col cols="4">
          <div>
            <v-text-field
              solo
              filled
              dense
              flat
              :hide-details="true"
              placeholder="Search Member . . ."
              autocomplete="off"
              v-model="searching"
              :readonly="process.run"
              clearable
              color="#F05326"
              @click:clear="searching = ''"
              v-on:keyup.enter="fetch()"
              prepend-inner-icon="mdi-magnify">
            </v-text-field>
          </div>
        </v-col>
        <v-col cols="4" class="text-right">
          <div class="d-flex float-right">
            <!-- <v-btn 
              depressed
              @click="filter"
              elevation="0"
              color="#F5F5F5" 
              class="black--text text-capitalize ml-5">
              Filter by
              <v-icon right>mdi-filter-variant</v-icon>
            </v-btn> -->
          </div>
        </v-col>
      </v-row>

      <div class="mt-3 mb-8">
        <v-skeleton-loader
          class="ma-auto"
          :loading="$store.state.process.run"
          :tile="false"
          transition="scale-transition"
          type="table-tbody">
          <v-data-table
            class="table-list"
            hide-default-footer
            fixed-header
            :loading="$store.state.process.run || process.run"
            :headers="headers"
            :items="items.list"
            :items-per-page.sync="items.limit"
            :page.sync="items.page"
            :server-items-length="items.total_data"
            item-key="id"
            :footer-props="{
              itemsPerPageText : 'Data per halaman',
              'items-per-page-options': [5,10, 20, 50, 100, 200, 350]
            }"
            >
            <template slot="footer" slot-scope="{ props }">
              <Pagination 
                @reload="fetch(page)"
                @changeLimit="limit = $event; fetch()" 
                @changePage="page = $event; fetch($event)" 
                :model="props"/>
            </template>

            <template slot="item" slot-scope="props">
              <tr class="mx-3 px-3 box-shadow">
                <td class="text-start white">
                  {{ props.item.fullname }}
                </td>
                <td class="text-start white">
                  {{ props.item.email }}
                </td>
                <td class="text-center white">
                  {{ props.item.phone }}
                </td>
                <td class="text-center white">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        color="blue"
                        v-bind="attrs"
                        v-on="on"
                        @click="change_redeem()">
                        <v-icon>mdi-cog-refresh-outline</v-icon>
                      </v-btn>
                    </template>
                    <span>Change Member Redeem</span>
                  </v-tooltip><v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        color="warning"
                        v-bind="attrs"
                        v-on="on"
                        @click="details(props.item)">
                        <v-icon>mdi-information-outline</v-icon>
                      </v-btn>
                    </template>
                    <span>Detail Member</span>
                  </v-tooltip>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-skeleton-loader>
      </div>
    </div>

  </div>
</template>

<script>
import { get, post, put , destroy } from '@/service/Axios'
import GridTable from '@/components/GridTable'
import Pagination from '@/components/Pagination.vue'
import Breadcrumbs from '@/components/Breadcrumbs.vue'
import Snackbar from '@/components/Snackbar.vue'
export default {
  components: { GridTable, Pagination, Breadcrumbs, Snackbar },
  data() {
    return {
      title:"List Users",
      limit: 10,
      page: 1,
      selected: {
        pagination: 0
      },
      headers: [
        { text: 'User Fullname',align: 'start',value: 'fullname',sortable: false },
        { text: 'Email',align: 'start',value: 'email',sortable: false },
        { text: 'Phone Number',align: 'center',value: 'phone',sortable: false },
        { text: 'Actions',align: 'end',value: 'action',sortable: false }
      ],
      breadcrumbs: [
        {
          text: 'List Member Redeem',
          disabled: false,
          href: '/redeem/member',
        },
        {
          text: 'List Member',
          disabled: true,
          href: '/member',
        }
      ],
      items:{},
      member: {},
      form: {
        member_id: ""
      },
      searching:"",
      process: {
        run: false
      },
      dialog: {
        detail: false,
        change_member: false
      }
    }
  },
  watch:{
    'selected.pagination': function(val) {
      this.fetch(val+1)
    },
    searching: _.debounce(function(newVal) {
      this.searching = newVal;
      this.fetch()
    }, 500),
  },
  created(){
    this.fetch()
  },
  mounted(){},
  methods:{
    changePage (event) {
      if (event === undefined) {
        this.selected.pagination = 0
      } 
    },
    filter(){
      this.$refs.snackbar.open("#4CAF50", `Mohon Maaf Fitur Belum Tersedia`);
    },
    async fetch(paging){
      let _self = this;
      _self.process.run = true

      await get(`users/v1/superuser/member`,{
        params:{
          limit: this.limit,
          page: paging,
          sort: "created_at",
          dir: "asc",
          member_redeem_id: this.$route.params.id,
          fullname: this.searching
        }
      }).then(response => {
        let res = response.data
        this.items = res.data
        this.process.run = false
        this.$store.state.process.run = false
        this.totalData = res.data.total_data
      })
    },
    details(item){
      this.member = item
      this.dialog.detail = true
    },
    change_redeem() {
      this.dialog.change_member = true
    },
    changeMemberRedeem() {
      let _self = this;
      _self.process.run = true
      put(`users/v1/superuser/member_redeem/change_member`, {
        data: {
          member_redeem_id: this.$route.params.id,
          member_id: this.form.member_id
        }
      }).then(response => {
        let res = response.data
        if (res.status) {
          this.$refs.snackbar.open("#4CAF50", `Berhasil Merubah Member Redeem`);
          this.dialog.change_member = false
          this.form = {
            member_id: ""
          }
          this.fetch()
        } else {
          this.$refs.snackbar.open("#D32F2F", `Gagal Merubah Member Redeem`);
        }
        this.process.run = false
      })
    }
  }
}
</script>

<style>

</style>